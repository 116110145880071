import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
  useTheme,
  Button,
} from '@material-ui/core';
import {
  ArrowBack,
  Fingerprint,
  LocalPharmacy,
  LibraryBooks,
  Create as Feather,
  Store,
  Loyalty as Tag,
  AccountCircle,
  PhoneInTalk,
  Language,
  Facebook,
  Twitter,
  Instagram,
  WhatsApp,
  Menu,
  Eco,
} from '@material-ui/icons';
// import { ThemeContext } from 'src/context/ThemeContext'; // Adjust path as per your project
import {
  setUserLocalInfo,
  signoutAction,
} from 'src/store/actions/auth.actions'; // Adjust path as per your project
import { useHistory } from 'react-router-dom';
import useLanguage from 'src/hooks/use-language';
import LanguageMenu from 'src/components/core/AppBar/TopBar/LanguageMenue';
import { SET_USER_UI_SETTINGS } from 'src/store/constants/app.settings.types';

// Social media links
const socials = [
  // { id: '1', icon: Facebook, link: 'https://www.facebook.com/DiwanWorld' },
  // { id: '2', icon: Twitter, link: 'https://twitter.com/diwanworld' },
  // { id: '3', icon: Instagram, link: 'https://www.instagram.com/diwanworld/' },
  // {
  //   id: '4',
  //   icon: WhatsApp,
  //   link: 'https://api.whatsapp.com/send?phone=96591111474',
  // },

  { icon: 'Icons-29.png', href: 'https://www.facebook.com/DiwanWorld' },
  { icon: 'Icons-30.png', href: 'https://www.instagram.com/diwanworld' },
  { icon: 'Icons-31.png', href: 'https://www.twitter.com/diwanworld' },
  {
    icon: 'whatsapp.png',
    link: 'https://api.whatsapp.com/send?phone=96591111474',
  },
];

// Drawer routes configuration
const drawerRoutes = [
  {
    name: 'diwan',
    icon: 'diwan',
    image: '/logo/Icons-35.png',
    // image: '/images/Diwan World App - Logo.svg',
    auth: false,
  },
  {
    name: 'celebrities',
    icon: Fingerprint,
    route: '/categories/celebrities',
    auth: false,
  },
  {
    name: 'pharmacy',
    route: '/categories/book_pharmacy',
    image: '/images/icons/Icons-40.png',
    auth: false,
  },
  {
    name: 'library',

    route: '/categories/library',
    image: '/images/icons/Icons-42.png',
    auth: false,
  },
  { name: 'authors', icon: Eco, route: '/authors', auth: false },
  {
    name: 'store',
    image: '/images/icons/Icons-46.png',
    route: '/store',
    auth: false,
  },
  { name: 'brands', icon: Tag, route: '/brands', auth: false },
  { name: 'myAccount', icon: AccountCircle, route: '/profile', auth: true },
  {
    name: 'contactUs',
    icon: PhoneInTalk,
    route: '/profile/5',
    auth: false,
  },
];

// Define styles using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 74, 135, 0.93)',
    color: 'white',
    height: '100%',
  },
  listItem: {
    color: 'white',
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    marginTop: 30,
  },
  socialIcon: {
    // marginRight: theme.spacing(1),
    color: 'white',
    marginRight: 10,
    outline: 0,
    padding: 0,
  },
  logo: {
    width: 28,
    height: 28,
    objectFit: 'contain',
  },
}));

export default function DrawerContent({ open, onClose }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { userInfo } = useSelector((state) => state.authReducer);

  const theme = useTheme();

  const closeDrawer = () => {
    dispatch({
      type: SET_USER_UI_SETTINGS,
      payload: { isMobileNavOpen: false },
    });
  };

  return (
    <div className={classes.headerRoot}>
      {/* Header Section */}
      <div>
        <IconButton onClick={closeDrawer}>
          <ArrowBack
            style={{
              color: 'white',
              transform: `scaleX(${i18n.dir() == 'rtl' ? -1 : 1})`,
            }}
          />
        </IconButton>
        <Button
          onClick={() => {
            if (!userInfo) {
              push('/signin');
            } else {
              dispatch(signoutAction());
            }
          }}
          style={{ backgroundColor: theme.palette.secondary.main }}
        >
          <Typography
            variant="button"
            style={{ color: 'white', textTransform: 'uppercase' }}
          >
            {t(!userInfo ? 'signinSignup' : 'signout')}
          </Typography>
        </Button>
      </div>
      {/* <Divider /> */}

      {/* Navigation List */}
      <List style={{ border: 'none', marginTop: 20 }}>
        {drawerRoutes.map(
          ({ name, icon: Icon, route, image, auth }) =>
            ((auth && userInfo) || !auth) && (
              <ListItem
                button
                key={Math.random().toString()}
                onClick={() => {
                  push(route);
                  // if (parent) {
                  //   push(`/${parent.toLowerCase()}/${route || name}`);
                  // } else {
                  //   push(`/${route || name}`);
                  // }
                  // onClose();
                }}
                className={classes.listItem}
              >
                <ListItemIcon>
                  {image ? (
                    <img
                      className={classes.logo}
                      src={image}
                      // src="/svg/top-logo.svg"
                      alt="Diwan"
                    />
                  ) : (
                    <Icon style={{ color: 'white' }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  style={{ textAlign: 'start' }}
                  primary={t(name)}
                />
              </ListItem>
            )
        )}

        {/* Language Switch */}
        <LanguageMenu inDrawer />
      </List>
      {/* <Divider /> */}

      {/* Social Media Links */}
      <div className={classes.socialIcons}>
        <Typography
          variant="body1"
          style={{ color: 'white', marginBottom: 10 }}
        >
          {t('followUs')}
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {socials.map((social) => (
            <IconButton
              key={Math.random().toString()}
              onClick={() => window.open(social.link, '_blank')}
              className={classes.socialIcon}
            >
              <img
                style={{
                  width: 30,
                  height: 30,
                  objectFit: 'contain',
                }}
                src={`/images/icons/${social.icon}`}
                alt={social.icon}
              />
              {/* <social.icon /> */}
            </IconButton>
          ))}
        </div>
      </div>
    </div>
  );
}
