import ApiService from 'src/services/api.service';

import {
  GET_APP_SETTINGS_FAIL,
  GET_APP_SETTINGS_REQUEST,
  GET_APP_SETTINGS_SUCCESS,
  GET_REFUNDS_POLICY_FAIL,
  GET_REFUNDS_POLICY_REQUEST,
  GET_REFUNDS_POLICY_SUCCESS,
  GET_RETURNS_POLICY_FAIL,
  GET_RETURNS_POLICY_REQUEST,
  GET_RETURNS_POLICY_SUCCESS,
  SET_IS_PHYSICAL_DEVICE,
} from '../constants/app.settings.types';

export const getReturnsPolicyActions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_RETURNS_POLICY_REQUEST });
    const { data } = await ApiService.get(
      `items/app_settings?filter[key]=return_policy`
    );

    dispatch({ type: GET_RETURNS_POLICY_SUCCESS, payload: data.data[0] });
  } catch (error) {
    console.log({ error });
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_RETURNS_POLICY_FAIL, error: message });
  }
};

export const getRefundsPolicyActions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_REFUNDS_POLICY_REQUEST });
    const { data } = await ApiService.get(
      `items/app_settings?filter[key]=refunds_policy`
    );

    dispatch({ type: GET_REFUNDS_POLICY_SUCCESS, payload: data.data[0] });
  } catch (error) {
    console.log({ error });
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_REFUNDS_POLICY_FAIL, error: message });
  }
};

export const getAppSettingsActions = () => async (dispatch) => {
  try {
    dispatch({ type: GET_APP_SETTINGS_REQUEST });
    const { data } = await ApiService.get(`items/app_settings`);

    // console.log(data.data);
    dispatch({ type: GET_APP_SETTINGS_SUCCESS, payload: data.data });
  } catch (error) {
    console.log({ error });
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({ type: GET_APP_SETTINGS_FAIL, error: message });
  }
};

export const setIsPhysicalDeviceActions =
  (isMobileDevice) => async (dispatch) => {
    dispatch({ type: SET_IS_PHYSICAL_DEVICE, payload: isMobileDevice });
  };
