import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { socialAuthAction } from 'src/store/actions/auth.actions';
import { jwtDecode } from 'jwt-decode';
import { useHistory } from 'react-router-dom';

function AuthCallback() {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    // Extract the hash fragment from the URL
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const idToken = params.get('id_token');

    // console.log({ idToken });

    if (idToken) {
      try {
        // Decode the JWT to get user info
        const decodedUserInfo = jwtDecode(idToken);

        setUserInfo(decodedUserInfo);

        console.log('Attempting redirect to dardiwan://auth-completed');
        // console.log({userInfo})
        window.location.href = `dardiwan://auth-completed?email=${decodedUserInfo.email}`;
      } catch (error) {
        console.error('Error decoding token:', error);
        push('/error'); // Redirect to error page
      }
    } else {
      console.error('No id_token found in URL');
      push('/error');
    }
  }, [push]);

  return (
    <div>
      {/* <p>v1</p>
      <p>{JSON.stringify(userInfo, null, 2)}</p> */}
    </div>
  );
}

export default AuthCallback;
