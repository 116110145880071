import { ThemeProvider } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

import withRoot from './withRoot';
import { theme } from './theme';
import { CssBaseline, makeStyles } from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import AppBar from './components/core/AppBar';
import HomePage from './views/home';
import Footer from './components/core/Footer';
import SignupPage from './views/SignupPage';
import NotFound from './views/NotFound';
import CatDetailsScreen from './views/CatDetailsScreen';
import CatsScreen from './views/CatsScreen';
import FilterScreen from './views/FilterScreen';
import ProductDetailsScreen from './views/ProductDetailsScreen';
import AccountScreen from './views/AccountScreen';
import PrivateRoute from './components/PrivateRoute';
import OrderDetails from './views/AccountScreen/OrderHistory/OrderDetails';
import OrderFaild from './views/shopping/Checkout/OrderFaild';
import PaymentStatusRedirect from './views/shopping/Checkout/PaymentStatusRedirect';
import OrderConfirmed from './views/shopping/Checkout/OrderConfirmed';
import SelectCountry from './components/SelectCountry';
import { useDispatch, useSelector } from 'react-redux';
import Cart from './views/shopping/Cart';
import ShippingAddress from './views/shopping/ShippingAddress';
import Checkout from './views/shopping/Checkout';
import AuthorsPage from './views/AuthorsPage';
import StoreScreen from './views/StoreScreen';
import HelmetMetaData from './components/HelmetMetaData';
import ForgotPassword from './views/AccountScreen/ForgotPassword';
import { useEffect, useState } from 'react';
import Notification from './components/Notification';
import MobileBottomNav from './components/mobile/MobileBottomNav';
import MobileHeader from './components/mobile/MobileHeader';
import SigninPage from './views/SigninPage';
import { setIsPhysicalDeviceActions } from './store/actions/app.settings.actions';
import { MOBILE_HEADER_HEIGHT } from './config';
import AuthCallback from './views/AuthCallback';
import AuthComplete from './views/AuthComplete';
// import { getTokenHandler } from './firebase';
// import { useEffect, useState } from 'react';

// Detect if running in a mobile webview
const isMobileWebview = () => {
  const userAgent = navigator.userAgent;
  return (
    (/Android/i.test(userAgent) && /wv/i.test(userAgent)) ||
    (/iPhone|iPad|iPod/i.test(userAgent) && !/Safari/i.test(userAgent))
  );
};

// const checkMobileDevice = () => {
//   // Check for mobile user agent
//   const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//   const mobileRegex =
//     /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

//   // Check if we're in a WebView
//   const isInWebView =
//     window.navigator.standalone ||
//     window.matchMedia('(display-mode: standalone)').matches ||
//     userAgent.includes('wv');

//   setIsMobileDevice(mobileRegex.test(userAgent) || isInWebView);
// };

const useClasses = makeStyles((theme) => ({
  separator: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      // height: 110,
      height: ({ mobileOS }) => (mobileOS !== 'unknown' ? 210 : 110),
    },
  },
}));

function App() {
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const dispatch = useDispatch();
  const { currencyInfo } = useSelector((state) => state.userReducer);
  const { isMobileDevice } = useSelector((state) => state.appSettingsReducer);

  const [mobileOS, setMobileOS] = useState();

  const classes = useClasses({ mobileOS });

  // const [isWebview, setIsWebview] = useState(false);
  // const [isMobileDevice, setIsMobileDevice] = useState(false);

  // const [passToShopping, setPassToShopping] = useState(false);

  // useEffect(() => {
  //   setPassToShopping(cartItems?.length > 0 ? true : false);
  // }, [cartItems]);

  // useEffect(() => {
  //   getToken(setTokenFound);
  // }, []);

  // Check if in webview when component mounts
  useEffect(() => {
    if (window.ReactNativeWebView) {
      navigator.geolocation.getCurrentPosition((position) => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'geolocation',
            data: position.coords,
          })
        );
      });
    }

    if (window.ReactNativeWebView) {
      window.open = function (url) {
        if (url.includes('accounts.google.com')) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: 'oauth',
              url: url,
            })
          );
          return;
        }
        window.location.href = url;
      };
    }

    // OAuth handling
    if (window.ReactNativeWebView) {
      window.open = function (url) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'oauth',
            url: url,
          })
        );
      };
    }

    const checkMobileDevice = () => {
      // Check for mobile user agent
      const userAgent = navigator.userAgent || window.opera;
      const mobileRegex =
        /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;

      // Check if we're in a WebView
      const isInWebView =
        window.navigator.standalone ||
        window.matchMedia('(display-mode: standalone)').matches ||
        userAgent.includes('wv');

      // console.log('mobileRegex', isMobileWebview());

      // dispatch(setIsPhysicalDeviceActions(1));
      dispatch(setIsPhysicalDeviceActions(isMobileWebview()));
    };

    checkMobileDevice();

    // setIsWebview(isMobileWebview());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <div
          className=""
          style={{
            paddingBottom: isMobileDevice && 56,
            marginTop: isMobileDevice && MOBILE_HEADER_HEIGHT,
          }}
        >
          <Notification />
          {isMobileDevice ? (
            <MobileHeader />
          ) : (
            <AppBar mobileOS={mobileOS} setMobileOS={setMobileOS} />
          )}
          <div className={classes.separator} id="app-bar-clearer" />
          <HelmetMetaData />
          {!currencyInfo ? (
            <SelectCountry />
          ) : (
            <Switch>
              <Route exact path="/" component={HomePage} />

              <Route path="/categories/:catName" component={CatsScreen} />
              <Route path="/authors/:authorId?" component={AuthorsPage} />
              <Route
                path="/category-details/:id"
                component={CatDetailsScreen}
              />
              <Route
                path="/filter/:table/:filter_by/:eq"
                component={FilterScreen}
              />
              <Route path="/search" component={FilterScreen} />
              <Route
                path="/products/:productId"
                component={ProductDetailsScreen}
              />

              <PrivateRoute
                path="/forgot-password"
                component={ForgotPassword}
                notAuthOnly={true}
              />
              <PrivateRoute
                path="/resetpassword"
                component={ForgotPassword}
                notAuthOnly={true}
              />
              <Route exact path="/auth-completed" component={AuthComplete} />
              <Route exact path="/auth/callback" component={AuthCallback} />
              <PrivateRoute
                path="/signin"
                component={SigninPage}
                notAuthOnly={true}
              />

              <PrivateRoute
                path="/signup"
                component={SignupPage}
                notAuthOnly={true}
              />
              <PrivateRoute
                path="/profile/:tabIndex?"
                component={AccountScreen}
              />

              <PrivateRoute path="/cart" component={Cart} />
              <PrivateRoute
                path="/shipping-address"
                component={ShippingAddress}
                shopping={true}
                // passToShopping={passToShopping}
                // to="/cart"
              />
              <PrivateRoute
                path="/checkout"
                component={Checkout}
                shopping={true}
                // to="/cart"
              />

              <PrivateRoute
                path="/order-success/:orderId/:date/:shipping_value/:subtotal/:price"
                component={OrderConfirmed}
              />
              <PrivateRoute path="/order-faild" component={OrderFaild} />

              <PrivateRoute
                path="/payment-status-check"
                component={PaymentStatusRedirect}
              />

              <PrivateRoute
                path="/order-details/:orderId"
                component={OrderDetails}
              />

              <Route path="/store" component={StoreScreen} />
              <Route path="/brands" component={StoreScreen} />

              <Route component={NotFound} />
            </Switch>
          )}
          {!isMobileDevice ? <Footer /> : <MobileBottomNav />}
        </div>
      </Router>

      {/* isWebview */}
    </ThemeProvider>
  );
}

export default withRoot(App);

// TODO: make sure all pages in XD are included
// TODO: unify drawer and reused components
// TODO: fix bug in sign in if email is not found
// TODO: logged in users should not be able to enter signin page
