import React, { useState, useEffect } from 'react';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home'; // Placeholder for custom Home icon
import StorefrontIcon from '@material-ui/icons/Storefront';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ShoppingBagIcon from '@material-ui/icons/ShoppingBasket';
// import { useHistory } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const bottomTabItems = [
  {
    text: 'diwan',
    href: '/',
    image: '/logo/Icons-35.png',
  },

  {
    text: 'pharmacy',
    href: '/categories/book_pharmacy',
    image: '/images/icons/Icons-40.png',
  },
  {
    text: 'celebrities',
    href: '/categories/celebrities',
    icon: FingerprintIcon,
  },
  {
    text: 'library',
    href: '/categories/library',
    image: '/images/icons/Icons-42.png',
  },
  {
    text: 'store',
    href: '/store',
    image: '/images/icons/Icons-46.png',
  },
];

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main, // Dark blue background
    position: 'fixed',
    height: '100%',
    bottom: 0,
    width: '100%',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height: 80, // Height of the bottom navigation
    zIndex: 1000, // Ensure the nav is above other elements
  },
  action: {
    color: 'white',
    '&.MuiBottomNavigationAction-label': {
      margin: 5,
    },
  },
  selected: {
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      '&.MuiBottomNavigationAction-label': {
        color: theme.palette.secondary.main,
      },
      '&.MuiSvgIcon-root': {
        color: theme.palette.secondary.main,
      },
    },
  },
  logo: {
    width: 28,
    height: 28,
    objectFit: 'contain',
  },
}));
function MobileBottomNav() {
  const [selectedTab, setSelectedTab] = useState(0); // 0 = Home tab active by default
  const classes = useStyles();
  const { t } = useTranslation();
  const { navItems } = useSelector((state) => state.pageReducer);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // set selectedTab to the current route
    const currentPath = window.location.pathname;
    switch (currentPath) {
      case '/':
        setSelectedTab(0);
        break;
      case '/categories/book_pharmacy':
        setSelectedTab(1);
        break;
      case '/categories/celebrities':
        setSelectedTab(2);
        break;
      case '/categories/library':
        setSelectedTab(3);
        break;
      case '/categories/store':
        setSelectedTab(4);
        break;
      default:
        setSelectedTab(0);
    }
  }, []);

  return (
    <BottomNavigation
      value={selectedTab}
      onChange={(event, newValue) => setSelectedTab(newValue)}
      showLabels
      className={classes.root}
    >
      {bottomTabItems.map(({ text, href, icon: Icon, image }) => (
        <BottomNavigationAction
          key={text}
          label={t(text)}
          href={href}
          icon={
            image ? (
              <img
                className={classes.logo}
                // style={{ width: 28 }}
                src={image}
                // src="/svg/top-logo.svg"
                alt={text}
              />
            ) : (
              <Icon style={{ color: 'white' }} />
            )
          }
          classes={{ root: classes.action, selected: classes.selected }}
        />
      ))}
      {/* <BottomNavigationAction
        label="Home"
        href="/"
        icon={<HomeIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Pharmacy"
        href="/categories/book_pharmacy"
        icon={<StorefrontIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Celebrities"
        href="/categories/celebrities"
        icon={<FingerprintIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Library"
        href="/categories/library"
        icon={<LibraryBooksIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      />
      <BottomNavigationAction
        label="Store"
        href="/categories/store"
        icon={<ShoppingBagIcon />}
        classes={{ root: classes.action, selected: classes.selected }}
      /> */}
    </BottomNavigation>
  );
}

export default MobileBottomNav;
