import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localStoragePrefix, USER_LANG } from 'src/config';

function useLanguage() {
  const { i18n } = useTranslation();
  const theme = useTheme();

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
    localStorage.setItem(localStoragePrefix + USER_LANG, lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    window.location.reload();
  };

  return changeLanguage;
}

export default useLanguage;
