export const localStoragePrefix = 'diwan-';
export const SHIPPING_ADDRESS = 'shippingAddress';
export const PAYMENT_METHOD = 'paymentMethod';
export const USER_LANG = 'lang';
export const DEFAULT_LANG =
  localStorage.getItem(localStoragePrefix + USER_LANG) || 'ar';
export const ROUTE_PATH = 'ROUTE_PATH';

export const appleStoreLink =
  'https://apps.apple.com/bh/app/diwan-world/id1581960846';
export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.diwanworld.app';

export const WEBSITE_URL = 'dardiwan.com';

export const MOBILE_HEADER_HEIGHT = 70;
