import React, { useState } from 'react';
import Dialog from 'src/components/Dialog';
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  TelegramIcon,
  WhatsappIcon,
  FacebookMessengerIcon,
  FacebookIcon,
  TwitterIcon,
} from 'react-share';

import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Share } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HelmetMetaData from './HelmetMetaData';
import { platform } from 'os';

// Function to generate share links for each platform
const generateShareLink = (platform, product) => {
  const { url, title } = product;
  switch (platform.toLowerCase()) {
    case 'whatsapp':
      return `https://wa.me/?text=${encodeURIComponent(title + ' ' + url)}`;
    case 'facebook':
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`;
    case 'x':
      return `https://x.com/intent/tweet?text=${encodeURIComponent(
        title
      )}&url=${encodeURIComponent(url)}`;
    case 'telegram':
      return `https://t.me/share/url?url=${encodeURIComponent(
        url
      )}&text=${encodeURIComponent(title)}`;
    default:
      return '';
  }
};

const sharePlateforms = [
  {
    btn: FacebookShareButton,
    icon: FacebookIcon,
    platform: 'facebook',
  },
  {
    btn: TwitterShareButton,
    icon: TwitterIcon,
    platform: 'x',
  },
  // {
  //   btn: FacebookMessengerShareButton,
  //   icon: FacebookMessengerIcon,
  //   platform: 'FacebookMessenger',
  // },
  {
    btn: WhatsappShareButton,
    icon: WhatsappIcon,
    platform: 'Whatsapp',
  },
  {
    btn: TelegramShareButton,
    icon: TelegramIcon,
    platform: 'Telegram',
  },
];

const useClasses = makeStyles(() => ({
  share: {
    transform: ({ dir }) => `scaleX(${dir == 'rtl' ? -1 : 1})`,
  },
}));

function ShareDialog({ shareInfo }) {
  const { t, i18n } = useTranslation();
  const classes = useClasses({ dir: i18n.dir() });
  const history = useHistory();
  const params = useParams();
  const [shareDialog, setShareDialog] = useState(false);
  const { product } = useSelector((state) => state.productReducer);
  const { isMobileDevice } = useSelector((state) => state.appSettingsReducer);

  const getShareUrl = () => {
    const SHARE_URL = process.env.REACT_APP_SHARE_URL;

    // return SHARE_URL + history.location.pathname`;

    const { productId } = params;
    return `${SHARE_URL}?productId=${productId}&quantity=${product?.quantity}`;
  };

  const renderShareDialog = () => (
    <Dialog
      open={shareDialog}
      closeDialog={() => setShareDialog(false)}
      title={t('selectPlateform')}
    >
      <div
        style={{
          display: 'flex',
          width: 400,
          justifyContent: 'space-around',
          padding: window.innerWidth >= 375 ? 20 : 0,
        }}
      >
        {sharePlateforms.map(
          ({ btn: ShareButton, icon: ShareIcon, platform }) => (
            <div key={Math.random().toString()}>
              <a
                href={generateShareLink(platform, {
                  title: shareInfo.title,
                  url: getShareUrl(),
                })}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: '#007bff' }}
              >
                <ShareIcon size={window.innerWidth >= 375 ? 44 : 25} round />
                {/* {name.charAt(0).toUpperCase() + name.slice(1)} */}
              </a>
              {/* <ShareButton
              url={getShareUrl()}
              title={shareInfo.title}
              description={shareInfo.description || ''}
            >
              <ShareIcon size={window.innerWidth >= 375 ? 44 : 25} round />
            </ShareButton> */}
            </div>
          )
        )}
      </div>
    </Dialog>
  );

  const handleShare = async () => {
    setShareDialog(true);
    // if (navigator.share && isMobileDevice) {
    //   // Use Web Share API if supported and not in WebView
    //   try {
    //     await navigator.share({
    //       title: shareInfo.title,
    //       text: shareInfo.description,
    //       url: getShareUrl(),
    //     });
    //   } catch (error) {
    //     console.error('Error sharing:', error);
    //   }
    // } else {
    //   // Show custom share dialog
    //   setShareDialog(true);
    // }
  };

  return (
    <>
      <HelmetMetaData
        title={shareInfo.title}
        description={shareInfo.description}
        quote={'Dardiwan - my lovely book'}
        // hashtag="#camperstribe"
      />
      <IconButton color="primary" onClick={handleShare}>
        <Share color="primary" fontSize="small" className={classes.share} />
      </IconButton>

      {renderShareDialog()}
    </>
  );
}

export default ShareDialog;
