import { IconButton } from '@material-ui/core';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';

import { socialAuthAction } from 'src/store/actions/auth.actions';

function SocialLogin() {
  const { isMobileDevice } = useSelector((state) => state.appSettingsReducer);
  const dispatch = useDispatch();

  const onSuccess = (response) => {
    console.log('google-auth-onSuccess');

    if (response.tokenId) {
      const payload = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
        from: 'google',
      };

      dispatch(socialAuthAction(payload));
    }
  };

  const onFailure = async () => null;

  return (
    <div style={{ display: 'flex' }}>
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        cookiePolicy={'single_host_origin'}
        uxMode={'redirect'}
        scope="email profile openid"
        // uxMode={isMobileDevice ? 'redirect' : 'popup'}
        render={(renderProps) => (
          <IconButton
            size="small"
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
          >
            <img
              style={{
                width: 50,
                height: 50,
                objectFit: 'contain',
              }}
              src={`/images/icons/Icons-28.png`}
            />
          </IconButton>
        )}
        onSuccess={onSuccess}
        onFailure={onFailure}
        redirectUri="https://diwanworld.com/auth/callback"
        // cookiePolicy={'single_host_origin'}
      />
    </div>
  );
}

export default SocialLogin;
