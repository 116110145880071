import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  MenuItem,
  ListItem,
  ListItemText,
  ListItemIcon,
  List,
} from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Language,
  Language as LanguageIcon,
} from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localStoragePrefix, USER_LANG } from 'src/config';
import { useSelector } from 'react-redux';

const langs = [
  { id: '1', name: 'العربية', key: 'ar' },
  { id: '2', name: 'English', key: 'en' },
  // { id: '3', name: 'Turkish', key: 'tr' },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 300,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      width: ({ inDrawer }) => !inDrawer && 50,
    },
  },
  btn: {
    outline: 0,
    '& span': {
      justifyContent: 'center !important',
    },
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: 0,
    },
  },
  langsIcon: {
    width: 14.4,
    height: 14.4,
    objectFit: 'cover',
    margin: '0 3px',
    display: 'visible',
    [theme.breakpoints.down('xs')]: {
      width: 13.4,
      height: 13.4,
      display: 'none',
    },
  },
  paper: {
    // border: `1px solid ${theme.palette.primary.main}`,
    border: `1px solid ${theme.custom.myTheme.grey2}`,
    borderRadius: 0,
  },
  userLang: {
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
  menuItem: {
    [theme.breakpoints.down('xs')]: {
      minHeight: '20px !important',
      // padding: '0 8px',
    },
  },
  langItem: {
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
    },
  },
  listItem: {
    color: 'white',
    textAlign: 'start',
  },
}));

export default function LanguageMenu({ props, inDrawer = false }) {
  const classes = useStyles({ inDrawer });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { lang } = useSelector((state) => state.userReducer);

  // const [selectedLang, setSelectedLang] = useState('');

  // language handler
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem(localStoragePrefix + USER_LANG, lng);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
    window.location.reload();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleLanguage = (lang) => {
    setOpen(false);
    // setSelectedLang(lang);
    changeLanguage(lang);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const getUserLang = (inDrawer) => {
    let userLang = langs.find((item) => item.key == lang).name;
    if (window.innerWidth >= 600 || inDrawer) return userLang;
    else {
      lang !== 'ar' && (userLang = userLang.slice(0, 2));
      return userLang;
    }
  };

  // useEffect(() => {
  //   setSelectedLang(lang);
  // }, []);

  return (
    <div className={classes.root} {...props}>
      {!inDrawer ? (
        <Button
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          className={classes.btn}
        >
          <img src="/svg/langs.svg" className={classes.langsIcon} />
          {/* <LanguageIcon color="primary" style={{ fontSize: 18 }} /> */}
          <Typography className={classes.userLang} color="primary">
            {getUserLang()}
          </Typography>
          <KeyboardArrowDownIcon color="primary" style={{ fontSize: 22 }} />
        </Button>
      ) : (
        // <List>
        <ListItem
          button
          className={classes.listItem}
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <ListItemIcon>
            <Language style={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary={getUserLang(inDrawer)} />
        </ListItem>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper} elevation={0}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {langs.map((lang) => (
                    <MenuItem
                      key={lang.id}
                      onClick={() => handleLanguage(lang.key)}
                      className={classes.menuItem}
                    >
                      <Typography
                        color="primary"
                        variant="button"
                        className={classes.langItem}
                      >
                        {lang.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
