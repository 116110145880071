import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { socialAuthAction } from 'src/store/actions/auth.actions';

function AuthComplete() {
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(useLocation().search);
  const email = searchParams.get('email');
  const { push } = useHistory();

  useEffect(() => {
    // console.log(email);
    // setEmail(email);

    if (email) {
      const payload = {
        email: email,
        first_name: email.split('@')[0],
        last_name: email.split('@')[0],
        from: 'google',
      };

      dispatch(socialAuthAction(payload));
      // window.location.href
      // window.location.reload();
      push('/');
    }
  }, [useLocation().search]);
  return <div></div>;
  // return <div style={{ background: 'red' }}>hello {email} you logged in!!</div>;
}

export default AuthComplete;
