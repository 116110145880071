import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  ArrowBack,
  Menu,
  FavoriteBorder,
  Search,
  ShoppingCart,
} from '@material-ui/icons';
import { setUserInfoAction } from 'src/store/actions/auth.actions'; // Adjust path as per your project
import { IconButton, Typography, useTheme } from '@material-ui/core';
import NavBar from '../core/AppBar/NavBar';
import MobileDrawer from './MobileDrawer';
import { SET_USER_UI_SETTINGS } from 'src/store/constants/app.settings.types';
import { MOBILE_HEADER_HEIGHT } from 'src/config';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import { theme } from 'src/theme';

// Define styles using Material-UI's makeStyles
const useStyles = makeStyles((theme) => ({
  headerSection: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F8F8F8',
    justifyContent: 'space-between',
    height: MOBILE_HEADER_HEIGHT,
    padding: '0 12px',
    alignItems: 'center',
    paddingBottom: 5,
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1000,
  },
  icon: {
    // margin: theme.spacing(1),
    cursor: 'pointer',
    color: theme.palette.primary.main,
    position: 'relative',
    fontSize: 28,
  },
  badge: {
    position: 'absolute',
    top: -5,
    right: 1,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: '50%',
    // padding: 4,
    minWidth: 16,
    minHeight: 16,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
  },
  badgeText: {
    fontSize: 12,
  },
}));

export default function MobileHeader() {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { push } = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const shouldShowBackButton = () => {
    const pathSegments = location.pathname.split('/').filter(Boolean);

    // Special case handling for routes like /categories/book_pharmacy
    // which might be considered single logical screens despite having multiple segments
    const singleScreenRoutes = [
      '/categories/book_pharmacy',
      '/categories/library',
      '/categories/celebrities',
    ];

    if (singleScreenRoutes.includes(location.pathname)) {
      // For these special routes, only show back if we have navigation history
      return history.length > 2;
    }
    // console.log('pathSegments', pathSegments.length);
    // console.log('history', history);
    // Otherwise, check if we have enough path segments or history
    return pathSegments.length > 1;
  };

  const handleBackClick = () => {
    history.back();
  };

  // Redux state selectors
  const { loading, cartItemsNum } = useSelector((state) => state.cartReducer);
  const { userInfo } = useSelector((state) => state.authReducer);
  const { t } = useTranslation();

  // Function to convert numbers to Arabic numerals
  const convertToArabicNumbers = (item) => {
    const arabicNumbers = ['۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return item
      .toString()
      .split('')
      .map((char) => (/\d/.test(char) ? arabicNumbers[char] : char))
      .join('');
  };

  // Fetch user info from localStorage if not present in Redux
  useEffect(() => {
    if (!userInfo) {
      const userData = JSON.parse(localStorage.getItem('userInfo'));
      if (userData) {
        dispatch(setUserInfoAction(userData));
      }
    }
  }, [userInfo, dispatch]);

  return (
    <div className={classes.headerSection}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 10,
          // height: 0,
        }}
      >
        {shouldShowBackButton() ? (
          <IconButton
            onClick={handleBackClick}
            style={{ outline: 0, padding: 0 }}
            color="primary"
          >
            <ArrowBack
              style={{ transform: `scaleX(${i18n.dir() == 'rtl' ? -1 : 1})` }}
            />
          </IconButton>
        ) : (
          <IconButton
            // onClick={() => setIsMobileNavOpen(true)}
            onClick={() =>
              dispatch({
                type: SET_USER_UI_SETTINGS,
                payload: { isMobileNavOpen: true },
              })
            }
            style={{ outline: 0, padding: 0 }}
            color="primary"
            // size="small"
          >
            {/* <div className="menu_burger"></div> */}
            <img
              style={{
                width: 25,
                transform: `scaleX(${i18n.dir() == 'rtl' ? -1 : 1})`,
              }}
              src="/images/icons/Icons-01.png"
              // src="/svg/top-logo.svg"
              alt="Diwan"
            />
            {/* <Menu className={classes.icon} /> */}
          </IconButton>
        )}
        <MobileDrawer />
        {/* <IconButton>
          <Menu className={classes.icon} />
        </IconButton> */}

        <IconButton
          onClick={() => push('/profile/4')}
          style={{ outline: 0, padding: 0 }}
        >
          <img
            style={{
              width: 25,
              transform: `scaleX(${i18n.dir() == 'rtl' ? -1 : 1})`,
            }}
            src="/images/Icons-02.png"
            // src="/svg/top-logo.svg"
            alt="Diwan"
          />
          {/* <FavoriteBorder className={classes.icon} /> */}
        </IconButton>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // gap: 20,
          // height: 0,
        }}
      >
        <Typography style={{ fontSize: 20, color: theme.palette.primary.main }}>
          {t('diwanWorld')}
        </Typography>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 10,
        }}
      >
        <IconButton style={{ outline: 0, padding: 0 }}>
          <Search className={classes.icon} />
        </IconButton>

        <IconButton
          onClick={() => push('/cart')}
          style={{ outline: 0, padding: 0, position: 'relative' }}
        >
          <img
            style={{
              width: 25,
              transform: `scaleX(${i18n.dir() == 'rtl' ? -1 : 1})`,
            }}
            src="/images/Icons-05.png"
            // src="/svg/top-logo.svg"
            alt="Diwan"
          />
          {/* <ShoppingCart className={classes.icon} /> */}
          {cartItemsNum > 0 && (
            <div className={classes.badge}>
              <span className={classes.badgeText}>
                {convertToArabicNumbers(cartItemsNum)}
              </span>
            </div>
          )}
        </IconButton>
      </div>
      {/* <ArrowBack
        className={classes.icon}
        onClick={() => {
          if (noBack) {
            push('/');
          } else {
            push(location?.state?.prevPath || '/');
          }
        }}
      /> */}
    </div>
  );
}

// One of your dependencies, babel-preset-react-app, is importing the
// "@babel/plugin-proposal-private-property-in-object" package without
// declaring it in its dependencies. This is currently working because
// "@babel/plugin-proposal-private-property-in-object" is already in your
// node_modules folder for unrelated reasons, but it may break at any time.

// babel-preset-react-app is part of the create-react-app project, which
// is not maintianed anymore. It is thus unlikely that this bug will
// ever be fixed. Add "@babel/plugin-proposal-private-property-in-object" to
// your devDependencies to work around this error. This will make this message
// go away.
